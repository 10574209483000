import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { RemoveDialogComponent } from 'src/app/remove-dialog/remove-dialog.component';
import { Lagerverwaltung, PlantsService } from '../../services/plants.service';

@Component({
  selector: 'app-lagerverwaltung',
  templateUrl: './lagerverwaltung.component.html',
  styleUrls: ['./lagerverwaltung.component.css'],
})
export class LagerverwaltungComponent {
  @ViewChild(MatTable)
  table!: MatTable<Lagerverwaltung>;

  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;

  displayedColumns: string[] = [];
  dataSource: any;
  constructor(private plantsService: PlantsService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.displayedColumns = [
      'Conwin',
      'Name',
      'Strasse',
      'plz',
      'Stadt',
      'Bezirk',
      /*'löschen',*/
    ];
    this.loadData();
  }

  removeData(item) {
    var dialogRef = this.openDialog('0ms', '0ms');
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        this.plantsService.delete(item.id).subscribe(
          (res) => {
            this.loadData();
          },
          (error) => {
            alert('Fehler beim Löschen');
          }
        );
      }
    });
  }

  loadData(): void {
    this.plantsService.get().subscribe((data) => {
      this.dataSource = new MatTableDataSource<Lagerverwaltung>(data);
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): MatDialogRef<RemoveDialogComponent, any> {
    var dialogref = this.dialog.open(RemoveDialogComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    return dialogref;
  }
}
