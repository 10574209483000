import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
})
export class SupportComponent implements OnInit {
  connection: signalR.HubConnection;
  messages: string[] = [];

  selectedCommand = new FormControl();
  messageText: FormControl = new FormControl(null);
  clientIdText: FormControl = new FormControl(null);

  constructor() {}

  ngOnInit(): void {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.signalRConnection + 'clientmessagehub')
      .build();

    this.connection
      .start()
      .then(() => {
        console.log('SignalR Connected!');
      })
      .catch((err) => {
        console.log('SignalR Connection Error: ' + err.toString());
        return console.error(err.toString());
      });

    this.connection.on('ReceiveMessage', (user, message) => {
      console.log(message);
      this.messages.push(user + ': ' + message);
      console.log(user, message);
    });

    this.connection.on('ReceiveBase64Message', (user, message) => {
      let decodedString = window.atob(message);
      this.messages.push(user + ': ' + decodedString);
      console.log(user, decodedString);
    });
  }

  sendMessage(): void {
    this.messages.push(
      'sendMessge to: ' +
        this.clientIdText.value +
        ' command: ' +
        this.selectedCommand.value +
        ' message: ' +
        this.messageText.value
    );

    console.log(
      'clientId',
      this.clientIdText.value,
      'command',
      this.selectedCommand.value,
      'message',
      this.messageText.value
    );

    this.connection
      .invoke(
        'Execute',
        Number(this.clientIdText.value),
        this.selectedCommand.value,
        this.messageText.value
      )
      .catch((err) => console.error(err));
  }
}
