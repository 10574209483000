import { Injectable } from '@angular/core';
import { AppConstants } from 'src/app/appConstants';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor() {}

  hasRole(role: Role) {
    let roles = JSON.parse(localStorage.getItem(AppConstants.ROLES));

    if (roles) {
      if (
        this.isSystemAdministartor(roles) ||
        (this.isAdministrator(roles) && role != Role.SystemAdmin)
      ) {
        return true;
      }
      return roles.indexOf(role) >= 0;
    }
    return false;
  }

  isAdministrator(roles: Role[]): boolean {
    return roles.indexOf(Role.Administrator) >= 0;
  }

  isSystemAdministartor(roles: Role[]): boolean {
    return roles.indexOf(Role.SystemAdmin) >= 0;
  }

  hasRoleWithConvert(role: string) {
    let roles = JSON.parse(localStorage.getItem(AppConstants.ROLES));

    if (roles) {
      if (
        this.isSystemAdministartor(roles) ||
        (this.isAdministrator(roles) && role != Role.SystemAdmin)
      ) {
        return true;
      }
      if (role in Role) {
        return roles.indexOf(role) >= 0;
      }
    }
    return false;
  }

  hasOneOfTheRoles(expectedRoles: Role[]) {
    let roles = JSON.parse(localStorage.getItem(AppConstants.ROLES));

    if (roles) {
      if (
        this.isSystemAdministartor(roles) ||
        (this.isAdministrator(roles) &&
          !expectedRoles.every((r: Role) => r == Role.SystemAdmin))
      ) {
        return true;
      }
      if (expectedRoles.length === 0) {
        return true;
      }

      for (let expectedRole of expectedRoles) {
        if (roles.indexOf(expectedRole) >= 0) {
          return true;
        }
      }
    }
    return false;
  }

  addRolesToStorage(roles: string[]) {
    let mappedRoles = this.mapRoles(roles);
    localStorage.setItem(AppConstants.ROLES, JSON.stringify(mappedRoles));
  }

  private mapRoles(roles: string[]): Role[] {
    let mappedRoles: Role[] = [];
    roles.forEach((role) => {
      if (role in Role) {
        mappedRoles.push(role as Role);
      }
    });
    return mappedRoles;
  }
}

export enum Role {
  SystemAdmin = 'SystemAdmin',
  Administrator = 'Administrator',
  CRAFT_TRACKING = 'CRAFT_TRACKING',
  CONTAINER_TRACKING = 'CONTAINER_TRACKING',
  SUPPORT_USER = 'SUPPORT_USER',
  MESSAGE_CENTER = 'MESSAGE_CENTER',
  PHONEBOOK_MANAGEMENT = 'PHONEBOOK_MANAGEMENT',
  DRIVER_MANAGEMENT = 'DRIVER_MANAGEMENT',
  PLANT_MANAGEMENT = 'PLANT_MANAGEMENT',
}
