<div class="nachrichten-lesen">
  <h1 class="gap">{{ "ui.table.message" | translate }}</h1>
</div>

  <form class="new-message-form">
    <mat-form-field class="new-message-width">
      <textarea matInput>{{ messageText }}</textarea>
    </mat-form-field>
  </form>

<button mat-raised-button color="primary" routerLink="/messages">
  {{ "ui.new-back" | translate }}
</button>




