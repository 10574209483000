import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DriversService } from 'src/app/services/drivers.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css'],
})
export class NewUserComponent {
  firstName: FormControl = new FormControl(null, [Validators.required]);
  lastName: FormControl = new FormControl(null, [Validators.required]);
  pin: FormControl = new FormControl(null, [
    Validators.required,
    Validators.maxLength(4),
  ]);
  employeeNr: FormControl = new FormControl(null, [
    Validators.required,
    Validators.maxLength(4),
  ]);

  constructor(private driversService: DriversService, private router: Router) {}

  sendMessage() {
    if (
      !this.firstName.valid ||
      !this.lastName.valid ||
      !this.pin.valid ||
      !this.employeeNr.valid
    ) {
      alert('invalid');
      return;
    }
    this.driversService
      .create(
        this.firstName.value,
        this.lastName.value,
        this.pin.value,
        this.employeeNr.value
      )
      .subscribe(
        () => {
          this.router.navigate(['/user-administration']);
        },
        (error) => {
          alert(error.error);
        }
      );
  }
}
