import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public message = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.login();
  }

  async login() {
    this.authService.signIn().catch((err: HttpErrorResponse) => {
      if (err.message.includes('connection')) {
        // Show alert message: 'Sie sind nicht mit dem Internet verbunden'
      } else {
        // Show alert message: unexpected error
      }
    });
  }
}
