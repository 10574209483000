import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CraftsService as CraftsService } from 'src/app/services/crafts.service';
import { CraftWithGpsDataDto } from 'src/app/models/craft';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { AzureMapComponent } from '../../azure-map/azure-map.component';
import { MapsService } from 'src/app/MapsService';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild(AzureMapComponent) map: MapsService;

  mapReady = false;
  craftList = new Array<CraftWithGpsDataDto>();
  craftsSelected = new Array<CraftWithGpsDataDto>();
  craftSelected: CraftWithGpsDataDto;
  selectedDate: Date = new Date();
  craftCategoryMap = new Map<string, Array<CraftWithGpsDataDto>>();
  noDataFound: boolean = false;
  dataSource = new MatTreeNestedDataSource<CraftNode>();
  treeControl = new NestedTreeControl<CraftNode>((node) => node.children);
  hasChild = (_: number, node: CraftNode) =>
    !!node.children && node.children.length > 0;
  defaultDate = new FormControl(new Date());

  constructor(private craftsService: CraftsService) {}
  ngAfterViewInit(): void {
    this.map.mapReady().then((res) => {
      this.mapReady = true;
      this.initCraftList();
    });
  }

  ngOnInit(): void {}

  initCraftList() {
    this.craftsService.getAll().subscribe((crafts) => {
      this.craftsService
        .checkHasRoute(new Date())
        .subscribe((craftsWithRoute) => {
          craftsWithRoute.forEach((craft) => {
            if (crafts.some((e) => e.craftId == craft.craftId)) {
              crafts.find((e) => e.craftId == craft.craftId).hasRoute = true;
            }
          });
        });
      this.craftList = crafts;
      this.groupCraftsByCategory(crafts);
      this.map.setCrafts(this.craftList);
    });
  }

  dateChanged(date: Date) {
    this.selectedDate = date;
    this.map.setCrafts(this.craftList);
    this.craftSelected = null;

    this.craftList.forEach((craft) => {
      craft.hasRoute = false;
    });

    this.craftsService
      .checkHasRoute(this.selectedDate)
      .subscribe((craftsWithRoute) => {
        craftsWithRoute.forEach((craft) => {
          if (this.craftList.some((e) => e.craftId == craft.craftId)) {
            this.craftList.find((e) => e.craftId == craft.craftId).hasRoute =
              true;
          }
        });
      });
  }

  showRoute(craft: CraftWithGpsDataDto) {
    if (this.craftSelected == null || this.craftSelected != craft) {
      this.craftsService
        .getByIdAndDate(craft.craftId, this.selectedDate)
        .subscribe((craftData) => {
          this.map.showRoute(craftData);
          this.craftSelected = craft;
        });
    } else {
      this.map.setCrafts(this.craftList);
      this.craftSelected = null;
    }
  }

  onSelectedCraftChange(craft: CraftWithGpsDataDto, event: any) {
    if (event.checked) {
      this.craftsSelected.push(craft);
    } else {
      const index = this.craftsSelected.indexOf(craft, 0);
      this.craftsSelected.splice(index, 1);
    }

    if (this.craftsSelected.length == 0) {
      this.map.setCrafts(this.craftList);
    } else {
      this.map.showCraft(this.craftsSelected);
    }
  }

  groupCraftsByCategory(craftList: CraftWithGpsDataDto[]) {
    // reset grouping
    this.craftCategoryMap = new Map();
    let categories = new Array<string>();

    // do grouping
    craftList.forEach((craft) => {
      if (this.craftCategoryMap.has(craft.category)) {
        this.craftCategoryMap.get(craft.category).push(craft);
      } else {
        categories.push(craft.category);
        this.craftCategoryMap.set(
          craft.category,
          new Array<CraftWithGpsDataDto>(craft)
        );
      }
    });

    let dataSourceData = Array<CraftNode>();

    for (let category of categories) {
      let craftNode = new CraftNode();

      craftNode.name = category;
      craftNode.children = new Array();
      this.craftCategoryMap.get(category).forEach((x) => {
        let childNode = new CraftNode();
        childNode.craft = x;
        childNode.name = x.craftSign;
        childNode.noGpsData = x.gpsData == null;
        craftNode.children.push(childNode);
      });
      dataSourceData.push(craftNode);
      this.treeControl.expand(craftNode);
    }
    this.dataSource.data = dataSourceData;

    if (categories.length == 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }
}

class CraftNode {
  name: string;
  craft?: CraftWithGpsDataDto;
  children?: CraftNode[];
  noGpsData: boolean;
}
