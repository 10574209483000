import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PhoneBook,
  PhoneBookServiceService,
} from 'src/app/services/phone-book-service.service';

@Component({
  selector: 'app-phone-edit',
  templateUrl: './phone-edit.component.html',
  styleUrls: ['./phone-edit.component.css'],
})
export class PhoneEditComponent {
  nameText: FormControl = new FormControl(null, [Validators.required]);
  phoneText: FormControl = new FormControl(null, [
    Validators.required,
    Validators.pattern('[- +()0-9]+'),
    Validators.maxLength(12),
  ]);

  messageText: FormControl;
  phonebook: PhoneBook;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private phoneBookService: PhoneBookServiceService
  ) {
    const id = this.route.snapshot.params['id'];
    const name = this.route.snapshot.params['name'];
    const number = this.route.snapshot.params['number'];
    this.phonebook = { id: id, name: name, number: number };

    this.nameText.setValue(this.phonebook.name);
    this.phoneText.setValue(this.phonebook.number);
  }

  save() {
    if (!this.nameText.valid || !this.phoneText.valid) {
      alert('invalid');
      return;
    }
    this.phoneBookService
      .update(this.phonebook.id, this.nameText.value, this.phoneText.value)
      .subscribe(() => {
        this.router.navigate(['/phone-book']);
      });
  }
}
