import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../authorization/services/auth.service';
import { PeriodicElement } from '../modules/messages/table-input/table-input.component';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingServiceService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) {}

  getIncommingMessages(): Observable<PeriodicElement[]> {
    var employeeId = this.authService.getCurrentUser().id;
    return this.apiService.get(
      'Message/IncommingMessages?employeeId=' + employeeId
    );
  }

  getOutgoingMessages(): Observable<OutgoingMessages[]> {
    var employeeId = this.authService.getCurrentUser().id;
    return this.apiService.get(
      'Message/OutgoingMessages?employeeId=' + employeeId
    );
  }

  createMessage(createMessage: CreateMessage): Observable<boolean> {
    createMessage.senderId = this.authService.getCurrentUser().id;
    return this.apiService.post('Message/Create', createMessage);
  }

  getActiveDrivers(): Observable<ActiveDriver[]> {
    return this.apiService.get('Drivers/ActiveDrivers');
  }

  getActiveCrafts(): Observable<ActiveCrafts[]> {
    return this.apiService.get('Crafts');
  }

  markMessageAsRead(messageId: number): Observable<boolean> {
    return this.apiService.post('Message/Read?messageId=' + messageId);
  }
}

export interface ActiveDriver {
  id: number;
  fullName: string;
}

export interface ActiveCrafts {
  id: number;
  craftNr: string;
}

export interface OutgoingMessages {
  messageText: string;
  recipientName: string;
  // Versanddatum: string;
  isRead: boolean;
  id: number;
  sendDateTime: Date;
}

export interface CreateMessage {
  messageText: string;
  senderId: number;
  recipients: Recipients[];
}

export interface Recipients {
  recipientType: string;
  recipientId: number;
}
