<mat-toolbar class="Nachrichtenausgang">
  <span>{{ "ui.table.outbox" | translate }}</span>
</mat-toolbar>

<div class="top">
  <section class="example-container mat-elevation-z8" tabindex="0">
  <table
    matSort
    mat-table
    [dataSource]="dataSource"
    class="tabellen-ausgang"
    matSort
  >
    <ng-container matColumnDef="recipientName">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>
        {{ "ui.table.receiver" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.recipientName }}</td>
    </ng-container>

    <ng-container matColumnDef="messageText">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>
        {{ "ui.table.message" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.messageText | slice : 0 : 25 }}
        {{ element.messageText.length > 25 ? "..." : "" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sendDateTime">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>
        {{ "ui.table.date" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.sendDateTime | date : "dd.MM.yyyy HH:mm" }} Uhr
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      [routerLink]="['/show-messages', row.messageText]"
      *matRowDef="let row; columns: displayedColumns;"
    ></tr>
  </table>
</section>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    #paginator
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

