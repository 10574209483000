<mat-toolbar color="primary" *ngIf="isAuthenticated()">
  <mat-toolbar-row>
    <a class="title-link" routerLink="/dashboard">
      <div class="flex">
        <img class="logo" src="assets/logo-ics-group.svg" />

        <span class="title">4mobileContainer</span>
      </div>
    </a>

    <span class="menu-spacer"></span>
    <div>
      <a
        *ngFor="let entry of menuEntriesAllowed"
        mat-button
        [routerLink]="entry.path"
        routerLinkActive="active"
      >
        {{ entry.name | translate }}
      </a>

      <button
        mat-button
        [matMenuTriggerFor]="menu"
        *ngIf="masterDataEntriesAllowed?.length > 0"
      >
        {{ "ui.menu.master data management" | translate }}
      </button>

      <mat-menu #menu="matMenu">
        <button
          *ngFor="let entry of masterDataEntriesAllowed"
          routerLinkActive="active"
          mat-menu-item
          class="drop"
          [routerLink]="entry.path"
        >
          {{ entry.name | translate }}
        </button>
      </mat-menu>
    </div>

    <span class="right-spacer"></span>
    <div class="inline clearfix">
      <span class="small-font">
        {{ user == null ? "" : user.name }}
        ({{
          selectedOrganization == null ? "" : selectedOrganization.companyName1
        }})
      </span>
      <div
        class="small-font inline"
        *ngIf="organizations != null && organizations.length > 1"
      >
        <button
          mat-icon-button
          [matMenuTriggerFor]="organizationsMenu"
          [matTooltip]="'ui.tooltip.chooseanotherclient' | translate"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #organizationsMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let organization of organizations"
            (click)="selectOrganization(organization.orgIdentifier)"
          >
            <img class="organizationIcon" [src]="organization.companyLogoUrl" />
            <span>{{ organization.companyName1 }}</span>
          </button>
        </mat-menu>
      </div>

      <div class="inline">
        <button
          mat-icon-button
          [matMenuTriggerFor]="languageMenu"
          [matTooltip]="'ui.tooltip.changelanguage' | translate"
        >
          <mat-icon>flag</mat-icon>
        </button>
        <mat-menu #languageMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let language of languageEntries"
            (click)="changeLanguage(language.short)"
          >
            <span>{{ language.name }}</span>
          </button>
        </mat-menu>
      </div>

      <div class="inline">
        <button
          mat-icon-button
          *ngIf="isAuthenticated()"
          (click)="logout()"
          [matTooltip]="'ui.tooltip.logout' | translate"
        >
          <mat-icon>logout</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
