import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from 'src/app/appConstants';
import { Employee } from 'src/app/models/employee';

@Injectable()
export class OrganizationIdInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let organizationId = this.getSelectedOrganizationId();
    if (organizationId != null) {
      request = request.clone({
        setHeaders: {
          OrganizationId: organizationId,
        },
      });
    }
    return next.handle(request);
  }

  getSelectedOrganizationId() {
    let userData: Employee = JSON.parse(
      localStorage.getItem(AppConstants.USER_DATA)
    );
    if (userData != null && userData.selectedOrgId != null) {
      return userData.selectedOrgId;
    }
    return null;
  }
}
