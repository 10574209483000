import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DriversService {
  constructor(private apiService: ApiService) {}

  get(): Observable<UserAdministration[]> {
    return this.apiService.get('Drivers');
  }

  create(
    firstName: string,
    lastName: string,
    pin: string,
    employeeNr: string
  ): Observable<any> {
    return this.apiService.post('Drivers', {
      firstName: firstName,
      lastName: lastName,
      pin: pin,
      employeeNr: employeeNr,
    });
  }

  update(
    id: number,
    firstName: string,
    lastName: string,
    pin: string
  ): Observable<any> {
    return this.apiService.put('Drivers', {
      id: id,
      firstName: firstName,
      lastName: lastName,
      pin: pin,
    });
  }

  delete(id: number): Observable<any> {
    return this.apiService.delete('Drivers?id=' + id);
  }
}

export interface UserAdministration {
  id: number;
  firstName: string;
  lastName: string;
  pin: string;
  employeeNr: string;
}

export interface UserAdministrationEdit {
  id: number;
  firstName: string;
  lastName: string;
  pin: string;
  employeeNr: string;
}
