<mat-toolbar class="Nachrichteneingang">
  <span>{{ "ui.table.receipt" | translate }}</span>
</mat-toolbar>

<div class="sss">
  <section class="example-container mat-elevation-z8" tabindex="0">
  <table
    matSort
    mat-table
    [dataSource]="dataSource"
    class="tabellen-eingang"
    matSort
  >
    <ng-container matColumnDef="senderName">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>
        {{ "ui.table.sender" | translate }}
      </th>
      <td
        [ngClass]="{ bold: !element.isRead }"
        mat-cell
        *matCellDef="let element"
      >
        {{ element.senderName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="messageText">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>
        {{ "ui.table.message" | translate }}
      </th>
      <td
        [ngClass]="{ bold: !element.isRead }"
        mat-cell
        *matCellDef="let element"
      >
        {{ element.messageText | slice : 0 : 25 }}
        {{ element.messageText.length > 25 ? "..." : "" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sendDateTime">
      <th mat-sort-header mat-header-cell *matHeaderCellDef>
        {{ "ui.table.date" | translate }}
      </th>
      <td
        [ngClass]="{ bold: !element.isRead }"
        mat-cell
        *matCellDef="let element"
      >
        {{ element.sendDateTime | date : "dd.MM.yyyy HH:mm" }} Uhr
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      (click)="messageClicked(row)"
      *matRowDef="let row; columns: displayedColumns;"
    ></tr>
  </table>
</section>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    #paginator
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
