import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { RoleService } from 'src/app/authorization/services/role.service';

@Directive({
  selector: '[needsRole]',
})
export class NeedsRoleDirective implements OnInit {
  constructor(private el: ElementRef, private roleService: RoleService) {}

  @Input() needsRole: string;

  ngOnInit(): void {
    let initialDisplayOption = this.el.nativeElement.style.display;
    this.el.nativeElement.style.display = 'none';

    if (this.roleService.hasRoleWithConvert(this.needsRole)) {
      this.el.nativeElement.style.display = initialDisplayOption;
    }
  }
}
