<h2 mat-dialog-title>
  {{ "ui.dialog.title.deleteconfirmation" | translate }}
</h2>
<mat-dialog-content class="mat-typography">
  <h3>
    {{ "ui.dialog.label.deleteconfirmation" | translate }}
  </h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="warn"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    {{ "ui.button.delete" | translate }}
  </button>
  <button mat-raised-button color="basic" mat-dialog-close>
    {{ "ui.button.cancel" | translate }}
  </button>
</mat-dialog-actions>
