export const environment = {
  production: true,
  apiUrl: 'https://dev-portal.container-4mobile.eu/api/',
  signalRConnection: 'https://dev-portal.container-4mobile.eu/',

  //apiUrl: 'https://localhost:5001/api/',
  userManagerSettings: {
    client_id: '4mobilecontainerwebplatform.web.client',
    response_type: 'id_token token',
    scope: 'openid email roles profile 4mobilecontainerwebplatform.api.all',
    loadUserDataInfo: true,
    loadUserInfo: true,
    automaticSilentRenew: true,

    authority: 'https://identity.ics-group.cloud',
    redirect_uri: 'https://dev-portal.container-4mobile.eu/auth-callback',
    post_logout_redirect_uri: 'https://dev-portal.container-4mobile.eu',
    silent_redirect_uri:
      'https://dev-portal.container-4mobile.eu/silent-renew.html',
  },
};
