import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DriversService,
  UserAdministrationEdit,
} from 'src/app/services/drivers.service';

@Component({
  selector: 'app-user-administration-edit',
  templateUrl: './user-administration-edit.component.html',
  styleUrls: ['./user-administration-edit.component.css'],
})
export class UserAdministrationEditComponent {
  firstNameText: FormControl = new FormControl(null, [Validators.required]);
  lastNameText: FormControl = new FormControl(null, [Validators.required]);
  pinText: FormControl = new FormControl(null, [
    Validators.required,
    Validators.maxLength(4),
  ]);
  employeeNrText: FormControl = new FormControl(
    { value: null, disabled: true },
    [Validators.required, Validators.maxLength(4)]
  );

  messageText: FormControl;
  UserAdministrationEdit: UserAdministrationEdit;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private driversService: DriversService
  ) {
    const id = this.route.snapshot.params['id'];
    const firstName = this.route.snapshot.params['firstName'];
    const lastName = this.route.snapshot.params['lastName'];
    const pin = this.route.snapshot.params['pin'];
    const employeeNr = this.route.snapshot.params['employeeNr'];
    this.UserAdministrationEdit = {
      id: id,
      firstName: firstName,
      lastName: lastName,
      pin: pin,
      employeeNr: employeeNr,
    };

    this.firstNameText.setValue(this.UserAdministrationEdit.firstName);
    this.lastNameText.setValue(this.UserAdministrationEdit.lastName);
    this.pinText.setValue(this.UserAdministrationEdit.pin);
    this.employeeNrText.setValue(this.UserAdministrationEdit.employeeNr);
  }

  save() {
    if (
      !this.firstNameText.valid ||
      !this.lastNameText.valid ||
      !this.pinText.valid
    ) {
      alert('invalid');
      return;
    }
    this.driversService
      .update(
        this.UserAdministrationEdit.id,
        this.firstNameText.value,
        this.lastNameText.value,
        this.pinText.value
      )
      .subscribe(() => {
        this.router.navigate(['/user-administration']);
      });
  }
}
