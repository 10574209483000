<div class="neuer-eintrag">
  <h1 class="ne">{{ "ui.table.edit.user" | translate }}</h1>
</div>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{ "ui.table.enter.family.name" | translate }}</mat-label>
    <input matInput #message [formControl]="lastNameText" />

    <!--

  <input matInput #message type="text" id="messageText" formControlName="messageText">
      -->
  </mat-form-field>
</form>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{ "ui.table.enter.vorname" | translate }}</mat-label>
    <input matInput #message [formControl]="firstNameText" />

    <!--

  <input matInput #message type="text" id="messageText" formControlName="messageText">
      -->
  </mat-form-field>
</form>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{ "ui.table.enter.pin" | translate }}</mat-label>
    <input matInput #message [formControl]="pinText" />
  </mat-form-field>
</form>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{ "ui.table.employeeNr" | translate }}</mat-label>
    <input matInput #message [formControl]="employeeNrText" />
  </mat-form-field>
</form>

<div class="example-button-row">
  <button mat-raised-button (click)="save()" color="primary">
    {{ "ui.support.safe" | translate }}
  </button>
  <button mat-raised-button routerLink="/user-administration" color="accent">
    {{ "ui.table.cancel" | translate }}
  </button>
</div>
