import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserManager } from 'oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstants } from 'src/app/appConstants';
import { Employee } from 'src/app/models/employee';
import { EmployeesService } from 'src/app/services/userDatas.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private manager: UserManager = new UserManager(
    environment.userManagerSettings
  );

  private currentUserSubject: BehaviorSubject<Employee>;
  public currentUser: Observable<Employee>;
  private user: User | null;
  public userSubject: BehaviorSubject<IUser | null> = new BehaviorSubject(null);

  constructor(private userService: EmployeesService, private router: Router) {
    this.manager.events.addUserLoaded((user) => {
      this.user = user;
    });

    this.currentUserSubject = new BehaviorSubject<Employee>(
      JSON.parse(localStorage.getItem(AppConstants.USER_DATA))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public getCurrentUser(): Employee {
    return this.currentUserSubject.value;
  }

  public getUser(): IUser {
    return this.userSubject.value;
  }

  getAuthorizationHeaderValue(): string {
    if (this.isAuthenticated()) {
      let token = `${this.user.token_type} ${this.user.access_token}`;
      return token;
    }
    return null;
  }

  signIn(redirectUrl = '/') {
    const data = {
      hash: redirectUrl,
    };
    return this.manager.signinRedirect({
      state: data,
    });
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  isCurrentUserSet(): boolean {
    return this.getCurrentUser() != null;
  }

  async completeAuthentication() {
    this.user = await this.manager.signinRedirectCallback();
    this.loadEmployee();
    this.router.navigate([this.user.state.hash]);
  }

  loadEmployee() {
    this.userSubject.next(this.user && this.user.profile);
    this.userService.getCurrent().subscribe((userdata) => {
      // This is done to avoid manual refresh after a new login.
      if (
        localStorage.getItem(AppConstants.USER_DATA) != JSON.stringify(userdata)
      ) {
        window.location.reload();
      }

      localStorage.setItem(AppConstants.USER_DATA, JSON.stringify(userdata));
      this.currentUserSubject.next(userdata);
    });
  }

  async signout() {
    this.currentUserSubject.next(null);
    this.userSubject.next(null);
    this.cleanLocalStorage();
    return this.manager.signoutRedirect();
  }

  cleanLocalStorage() {
    localStorage.removeItem(AppConstants.USER_DATA);
    localStorage.removeItem(AppConstants.ROLES);
    localStorage.removeItem(AppConstants.LANGUAGE);
  }
}

export interface IUser {
  name?: string;
  role?: string;
}
