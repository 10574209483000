import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import {
  ActiveCrafts,
  ActiveDriver,
  CreateMessage,
  MessagingServiceService,
  Recipients,
} from 'src/app/services/messaging-service.service';

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.css'],
})
export class NewMessageComponent implements OnInit {
  regForm: FormGroup;
  SelectedCrafts = new FormControl();
  SelectedDrivers = new FormControl();
  Crafts: ActiveCrafts[] = [];
  Drivers: ActiveDriver[] = [];

  messageText: FormControl;
  sending: boolean = false
  constructor(
    private messagingService: MessagingServiceService,
    formBuilder: FormBuilder,
    private router: Router,
    private snackbar: MatSnackBar
  ) {
    this.regForm = formBuilder.group({
      messageText: this.messageText,
    });
  }

  ngOnInit(): void {
    this.messagingService.getActiveDrivers().subscribe((data) => {
      this.Drivers = data;
    });
    this.messagingService.getActiveCrafts().subscribe((data) => {
      this.Crafts = data;
    });
  }

  sendMessage() {
    this.sending = true;
    let recipients: Recipients[] = [];

    if (this.SelectedDrivers.value != null) {
      this.SelectedDrivers.value.map((item: ActiveDriver) => {
        recipients.push({ recipientType: 'Employee', recipientId: item.id });
      });
    }
    if (this.SelectedCrafts.value != null) {
      this.SelectedCrafts.value.map((item: ActiveCrafts) => {
        recipients.push({ recipientType: 'Craft', recipientId: item.id });
      });
    }

    if (recipients.length == 0) {
      this.snackbar.open('Please select at least one recipient', '', {
        duration: 5000,
      });
      this.sending = false;
      return;
    }

    var messageText = this.regForm.get('messageText').value;
    if (messageText == null || messageText == '') {
      this.snackbar.open('Please enter a message', '', {
        duration: 5000,
      });
      this.sending = false;
      return;
    }

    var message: CreateMessage = {
      messageText: messageText,
      senderId: 0,
      recipients: recipients,
    };

    this.messagingService.createMessage(message).subscribe(() => {
      this.router.navigate(['/messages']);
    });

  }
}
