import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PhoneBookServiceService {
  constructor(private apiService: ApiService) {}

  get(): Observable<PhoneBook[]> {
    return this.apiService.get('Phonebook');
  }

  create(name: string, number: string): Observable<any> {
    return this.apiService.post('Phonebook', { name: name, number: number });
  }

  update(id: number, name: string, number: string): Observable<any> {
    return this.apiService.put('Phonebook', {
      id: id,
      name: name,
      number: number,
    });
  }

  delete(id: number): Observable<any> {
    return this.apiService.delete('Phonebook?id=' + id);
  }
}

export interface PhoneBook {
  id: number;
  name: string;
  number: string;
}

export interface CreatePhoneBook {
  name: string;
  number: string;
}
