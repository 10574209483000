import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, interval } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { AppConstants } from 'src/app/appConstants';
import {
  AuthService,
  IUser,
} from 'src/app/authorization/services/auth.service';
import { Role, RoleService } from 'src/app/authorization/services/role.service';
import { AuthorizationInfo } from 'src/app/models/authorizationInfo';
import { Employee } from 'src/app/models/employee';
import { EmployeesService } from 'src/app/services/userDatas.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  user: IUser = {};
  userData: Employee;
  organizations: AuthorizationInfo[] = [];
  selectedOrganization: AuthorizationInfo;
  menuEntriesAllowed: Array<MenuEntry> = new Array<MenuEntry>();
  allMenuEntries: Array<MenuEntry> = new Array<MenuEntry>();
  masterDataEntries: Array<MenuEntry> = new Array<MenuEntry>();
  masterDataEntriesAllowed: Array<MenuEntry> = new Array<MenuEntry>();

  languageEntries: LanguageEntry[] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private userdataService: EmployeesService,
    private roleService: RoleService,
    public translateService: TranslateService
  ) {
    this.languageEntries.push(new LanguageEntry('English', 'en-US'));
    this.languageEntries.push(new LanguageEntry('Deutsch', 'de-DE'));

    let cachedLang = localStorage.getItem(AppConstants.LANGUAGE);
    if (cachedLang) {
      this.translateService.use(cachedLang);
    }

    this.allMenuEntries.push(
      new MenuEntry('ui.menu.dashboard', '/dashboard', [])
    );
    this.allMenuEntries.push(
      new MenuEntry('ui.menu.containers', '/containers', [
        Role.CONTAINER_TRACKING,
      ])
    );
    this.allMenuEntries.push(
      new MenuEntry('ui.menu.message', '/messages', [Role.MESSAGE_CENTER])
    );
    this.allMenuEntries.push(
      new MenuEntry('ui.menu.support', '/support', [Role.SystemAdmin])
    );

    this.masterDataEntries.push(
      new MenuEntry('ui.menu.phone.book', '/phone-book', [
        Role.PHONEBOOK_MANAGEMENT,
      ])
    );

    this.masterDataEntries.push(
      new MenuEntry('ui.menu.user.administration', '/user-administration', [
        Role.DRIVER_MANAGEMENT,
      ])
    );
    this.masterDataEntries.push(
      new MenuEntry('ui.menu.warehouse.management', '/lagerverwaltung', [
        Role.PLANT_MANAGEMENT,
      ])
    );
  }

  async ngOnInit(): Promise<void> {
    let stop = false;
    interval(500)
      .pipe(takeWhile(() => !stop))
      .subscribe(() => {
        if (this.isAuthenticated()) {
          this.loadEmployee(false);
          stop = true;
        }
      });
  }

  isAuthenticated() {
    return (
      this.authService.isAuthenticated() && this.authService.isCurrentUserSet()
    );
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  selectOrganization(orgIdentifier: string) {
    this.userdataService.selectOrganization(orgIdentifier).subscribe(() => {
      this.loadEmployee(true);
    });
  }

  loadEmployee(refreshAfterwards: boolean) {
    this.user = this.authService.getUser();
    this.userData = this.authService.getCurrentUser();

    this.userdataService
      .getAvailableOrganizations()
      .subscribe((organizations) => {
        this.organizations = organizations;

        this.selectedOrganization = this.organizations.find(
          (x) =>
            x.orgIdentifier.toLocaleLowerCase() ==
            this.userData.selectedOrgId.toLocaleLowerCase()
        );

        this.roleService.addRolesToStorage(this.selectedOrganization.userRoles);

        this.loadMenusBasedOnRoles();

        if (refreshAfterwards) {
          location.reload();
        }
      });
  }

  loadMenusBasedOnRoles() {
    this.menuEntriesAllowed = [];
    for (let menu of this.allMenuEntries) {
      if (this.roleService.hasOneOfTheRoles(menu.role)) {
        this.menuEntriesAllowed.push(menu);
      }
    }

    for (let menu of this.masterDataEntries) {
      if (this.roleService.hasOneOfTheRoles(menu.role)) {
        this.masterDataEntriesAllowed.push(menu);
      }
    }
  }

  logout(): void {
    this.authService.signout();
  }

  public changeLanguage(language: string): void {
    localStorage.setItem(AppConstants.LANGUAGE, language);
    this.translateService.use(language);
  }
}

class MenuEntry {
  name: string;
  path: string;
  role: Role[];

  constructor(name: string, path: string, role: Role[]) {
    this.name = name;
    this.path = path;
    this.role = role;
  }
}

class LanguageEntry {
  name: string;
  short: string;

  constructor(name: string, short: string) {
    this.name = name;
    this.short = short;
  }
}
