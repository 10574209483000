import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { RemoveDialogComponent } from 'src/app/remove-dialog/remove-dialog.component';
import {
  PhoneBook,
  PhoneBookServiceService,
} from 'src/app/services/phone-book-service.service';

@Component({
  selector: 'app-phone-book',
  templateUrl: './phone-book.component.html',
  styleUrls: ['./phone-book.component.css'],
})
export class PhoneBookComponent implements OnInit {
  @ViewChild(MatTable)
  table!: MatTable<PhoneBook>;

  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;

  displayedColumns: string[] = [];
  dataSource: any;
  constructor(
    private phoneBookService: PhoneBookServiceService,
    private router: Router,
    public dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.displayedColumns = ['Name', 'Phone', 'löschen'];
    this.loadData();
  }

  removeData(item) {
    var dialogRef = this.openDialog('0ms', '0ms');
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        this.phoneBookService.delete(item.id).subscribe(
          (res) => {
            this.loadData();
          },
          (error) => {
            alert('Fehler beim Löschen');
          }
        );
      }
    });
  }

  loadData(): void {
    this.phoneBookService.get().subscribe((data) => {
      this.dataSource = new MatTableDataSource<PhoneBook>(data);
      this.dataSource.paginator = this.paginator;
    });
  }

  entryClicked(row: PhoneBook): void {
    this.router.navigate(['/phone-edit', row.id, row.name, row.number]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): MatDialogRef<RemoveDialogComponent, any> {
    var dialogref = this.dialog.open(RemoveDialogComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    return dialogref;
  }
}
