import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizationInfo } from '../models/authorizationInfo';
import { Employee } from '../models/employee';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<Employee[]> {
    return this.apiService.get('Employees');
  }

  get(id: string): Observable<Employee> {
    return this.apiService.get('Employees/' + id);
  }

  getAvailableOrganizations(): Observable<AuthorizationInfo[]> {
    return this.apiService.get('Employees/AuthorizationInfo');
  }

  getCurrent(): Observable<Employee> {
    return this.apiService.get('Employees/current');
  }

  selectOrganization(organizationId: string): Observable<Employee> {
    return this.apiService.put(
      'Employees/selectOrganization/' + organizationId
    );
  }

  update(userData: Employee): Observable<Employee> {
    return this.apiService.put('Employees/' + userData.id, userData);
  }

  delete(id: string): Observable<Employee> {
    return this.apiService.delete('Employees/' + id);
  }
}
