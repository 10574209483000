<div class="button">
  <button mat-raised-button color="primary" routerLink="/new-messages">
    {{ "ui.button.new" | translate }}
  </button>
</div>




<div class="tables flex">
  <app-table-output class="table"></app-table-output>
  <div class="spacer"></div>
  <app-table-input class="table"></app-table-input>
  <div class="spacer"></div>
</div>




