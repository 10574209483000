import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/authorization/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.processRequestWithToken(
      this.authService.getAuthorizationHeaderValue(),
      request,
      next
    );
  }

  private processRequestWithToken(
    token: string,
    req: HttpRequest<any>,
    next: HttpHandler
  ) {
    if (!!token) {
      req = req.clone({
        setHeaders: {
          Authorization: `${token}`,
        },
      });
    }
    return next.handle(req).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }

            var queryParamsValues = null;
            if (this.router.url != '/' && this.router.url != '/login') {
              queryParamsValues = {
                returnUrl: this.router.url,
              };
            }

            this.router.navigate(['/login'], {
              queryParams: queryParamsValues,
            });
          }
        }
      )
    );
  }
}
