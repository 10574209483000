import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { MatSort, MatSortable } from '@angular/material/sort';
import {
  MessagingServiceService,
  OutgoingMessages,
} from 'src/app/services/messaging-service.service';

@Component({
  selector: 'app-table-output',
  templateUrl: './table-output.component.html',
  styleUrls: ['./table-output.component.css'],
})
export class TableOutputComponent implements OnInit {
  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;
  selection = new SelectionModel<OutgoingMessages>(true, []);
  displayedColumns: string[] = [];
  dataSource: any;
  constructor(private messagingService: MessagingServiceService) {}

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.displayedColumns = ['recipientName', 'messageText', 'sendDateTime'];
    this.messagingService.getOutgoingMessages().subscribe((data) => {
      this.dataSource = new MatTableDataSource<OutgoingMessages>(data);
      this.dataSource.paginator = this.paginator;
      this.sort.sort(<MatSortable>{
        id: 'sendDateTime',
        start: 'desc',
      });
      this.dataSource.sort = this.sort;
    });
  }
}
