<div class="button10">
  <button class="icon-button" mat-raised-button color="primary" routerLink="/new-user">
    {{ "ui.button.new" | translate }}
  </button>
</div>

<mat-form-field>
  <mat-label>{{ "ui.search" | translate }}</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input />
</mat-form-field>

<mat-toolbar class="Benutzerverwaltung">
  <span>{{ "ui.menu.user.administration" | translate }}</span>
</mat-toolbar>

<div class="sss">
  <section class="example-container mat-elevation-z8" tabindex="0">
    <table
      mat-table
      [dataSource]="dataSource"
      class="tabelle-benutzerverwaltung"
      matSort
    >
      <ng-container matColumnDef="Nachname">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.table.family-name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" (click)="entryClicked(element)">
          {{ element.lastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Vorname">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.table.vorname" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" (click)="entryClicked(element)">
          {{ element.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Pin">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.table.pin" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" (click)="entryClicked(element)">
          {{ element.pin }}
        </td>
      </ng-container>

      <ng-container matColumnDef="MitarbeiterNr">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.table.employeeNr" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" (click)="entryClicked(element)">
          {{ element.employeeNr }}
        </td>
      </ng-container>

      <ng-container matColumnDef="löschen">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.button.phone.book.delete" | translate }}
        </th>

      <td mat-cell *matCellDef="let element">
        
        <button
        (click)="removeData(element)"
        class="remove-button-admin"
        mat-icon-button>
        
        <mat-icon>delete</mat-icon> 
        </button>
        
      </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </section>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    #paginator
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>

<!--

 <div class="right">
    <button mat-icon-button (click)="sendMessage()" matTooltip="Senden">
      <mat-icon>done</mat-icon>
    </button>

    <button
      color="warn"
      routerLink="/messages"
      mat-icon-button
      matTooltip="Abbrechen"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>







<button
routerLink="/messages"
mat-icon-button
matTooltip="Abbrechen">

<mat-icon>delete</mat-icon> </button>



-->
