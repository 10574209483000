import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PhoneBookServiceService } from 'src/app/services/phone-book-service.service';

@Component({
  selector: 'app-new-phone-book-entry',
  templateUrl: './new-phone-book-entry.component.html',
  styleUrls: ['./new-phone-book-entry.component.css'],
})
export class NewPhoneBookEntryComponent {
  nameText: FormControl = new FormControl(null, [Validators.required]);
  phoneText: FormControl = new FormControl(null, [
    Validators.required,
    Validators.pattern('[- +()0-9]+'), Validators.maxLength(12)
  ]);

  constructor(
    private phoneBookService: PhoneBookServiceService,
    private router: Router
  ) {}

  sendMessage() {
    if (!this.nameText.valid || !this.phoneText.valid) {
      alert('invalid');
      return;
    }
    this.phoneBookService
      .create(this.nameText.value, this.phoneText.value)
      .subscribe(() => {
        this.router.navigate(['/phone-book']);
      });
  }
}
