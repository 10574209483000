<mat-form-field>
  <mat-label>{{ "ui.search" | translate }}</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>
  
  <mat-toolbar class="Lagerverwaltung">
  <span>{{ "ui.menu.warehouse.management" | translate }}</span>
  </mat-toolbar>
  
  <div class="lag">
  <section class="example-container mat-elevation-z8" tabindex="0">
  <table
    mat-table
    [dataSource]="dataSource"
    class="tabelle-Lagerverwaltung"
  >
    
    <ng-container matColumnDef="Conwin">
      <th mat-header-cell *matHeaderCellDef>
        {{ "ui.table.lager.conwin" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.externalKey}}</td>
    </ng-container>
  
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef>
        {{ "ui.table.name" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="Strasse">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.table.lager.street" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.street }}</td>
      </ng-container>

      <ng-container matColumnDef="plz">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.table.lager.postcode" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.zipCode }}</td>
      </ng-container>

      <ng-container matColumnDef="Stadt">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.table.lager.city" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.city }}</td>
      </ng-container>

      <ng-container matColumnDef="Bezirk">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.table.lager.dirstrict" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.district }}</td>
      </ng-container>
  <!--
    <ng-container matColumnDef="löschen">
      <th mat-header-cell *matHeaderCellDef>
        {{ "ui.button.phone.book.delete" | translate }}
      </th>
  
      <td mat-cell *matCellDef="let element">
        
        <button 
        (click)="removeData(element)"
        class="remove-button.lager"
        mat-icon-button>
        
        <mat-icon>delete</mat-icon> 
        </button>
        
      </td>
  
    </ng-container>
  -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  </section>
  
  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    #paginator
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
  </div>
  