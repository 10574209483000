import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-auth-call-back',
  templateUrl: './auth-call-back.component.html',
  styleUrls: ['./auth-call-back.component.scss'],
})
export class AuthCallBackComponent implements OnInit {
  error: boolean;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    // this.authService.completeAuthentication();
    // stops onInit if an error in authetication appears
    if (
      this.route.snapshot.fragment &&
      this.route.snapshot.fragment.indexOf('error') >= 0
    ) {
      this.error = true;
      return;
    }

    // Promise because not rxJs and needs to be awaited with no more code
    // Needs to be awaited because we need the token to do more
    this.authService.completeAuthentication();
  }
}
