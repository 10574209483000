import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  styleUrls: ['./show-message.component.css'],
})
export class ShowMessageComponent implements OnInit {
  messageText: string;
  
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.messageText = this.route.snapshot.params['messageText'];
  }
}
