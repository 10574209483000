<div class="neuer-benutzer">
  <h1 class="nb">{{ "ui.table.new.user" | translate }}</h1>
</div>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{ "ui.table.enter.family.name" | translate }}</mat-label>
    <input
      matInput
      #message
      type="text"
      id="lastName"
      [formControl]="lastName"
    />
  </mat-form-field>
</form>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{ "ui.table.enter.vorname" | translate }}</mat-label>
    <input
      matInput
      #message
      type="text"
      id="firstName"
      [formControl]="firstName"
    />
  </mat-form-field>
</form>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{ "ui.table.enter.pin" | translate }}</mat-label>
    <input matInput #message type="text" id="pin" [formControl]="pin" />
  </mat-form-field>
</form>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{ "ui.table.employeeNr" | translate }}</mat-label>
    <input
      matInput
      #message
      type="text"
      id="employeeNr"
      [formControl]="employeeNr"
    />
  </mat-form-field>
</form>

<div class="example-button-row">
  <button mat-raised-button (click)="sendMessage()" color="primary">
    {{ "ui.support.safe" | translate }}
  </button>
  <button mat-raised-button routerLink="/user-administration" color="accent">
    {{ "ui.table.cancel" | translate }}
  </button>
</div>
