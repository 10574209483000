<div class="Support">
  <h1 class="sup">Support</h1>
</div>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>{{ "ui.support.user" | translate }}</mat-label>
    <input matInput #message [formControl]="clientIdText" />
  </mat-form-field>
</form>

<div class="box">
  <div class="select">
    <mat-form-field appearance="fill">
      <mat-label>{{ "ui.support.command" | translate }}</mat-label>
      <mat-select [formControl]="selectedCommand" disableRipple>
        <mat-option value="DoFullSync">Full Sync</mat-option>
        <mat-option value="SqlSelect">Sql Select</mat-option>
        <mat-option value="SqlNonQuery">Sql Non Query</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<form class="example-form">
  <mat-form-field class="example-full-width1">
    <mat-label>{{ "ui.support.message" | translate }}</mat-label>
    <input matInput #message [formControl]="messageText" />
  </mat-form-field>
</form>

<button mat-raised-button color="primary" (click)="sendMessage()">
  {{ "ui.support.send" | translate }}
</button>

<p *ngFor="let message of messages">{{ message }}</p>
