<mat-drawer-container autosize class="container" matSort>
  <mat-drawer opened mode="side" class="side-panel">
    <span>
      <div *ngIf="showDetails">
        <button class="myButton" mat-icon-button (click)="goBack()">
          <mat-icon class="mat-icon-rtl-mirror" (click)="goBack()">
            arrow_back
          </mat-icon>
        </button>

        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'ui.table.header.containerNr' | translate }}"
            disabled="true"
            [value]="selectedContainer.containerNr"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'ui.table.header.containerType' | translate }}"
            disabled="true"
            [value]="selectedContainer.containerType"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'ui.table.header.containerGroup' | translate }}"
            disabled="true"
            [value]="selectedContainer.containerGroup"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'ui.table.header.status' | translate }}"
            disabled="true"
            [value]="selectedContainer.currentStatus"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-filter-table
          #table
          class="matTable"
          [dataSource]="dataSource"
          [pagination]="true"
          [filter]="true"
          (click)="onClick()"
          (rowDoubleClick)="onDoubleClick($event)"
        >
        </mat-filter-table>
      </div>
    </span>
  </mat-drawer>
  <mat-drawer-content>
    <app-azure-map #map></app-azure-map>
  </mat-drawer-content>
</mat-drawer-container>
