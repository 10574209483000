import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class PlantsService {

  constructor(private apiService: ApiService) { }


  get(): Observable<Lagerverwaltung[]> {
    return this.apiService.get('Plants');
  }


  delete(id: number): Observable<any> {
    return this.apiService.delete('Plants?id=' + id);
  }

}

export interface Lagerverwaltung {
  id: number;
  externalKey: number;
  name: string;
  street: string;
  zipCode: number;
  city: string;
  district: string;
}
