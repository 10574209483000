<button
  class="button7"
  mat-raised-button
  routerLink="/new-phone-book-entry"
  color="primary"
>
  {{ "ui.button.new" | translate }}
</button>

<div>
  <mat-form-field>
      <mat-label>{{ "ui.search" | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event)" #input>
  </mat-form-field>
</div> 

<mat-toolbar class="Telefonbuch">
  <span>{{ "ui.menu.phone.book" | translate }}</span>
</mat-toolbar>

<div class="sss">
  <section class="example-container mat-elevation-z8" tabindex="0">
    <table
      mat-table
      [dataSource]="dataSource"
      class="tabelle-telefonbuch"
      matSort
    >
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.table.name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" (click)="entryClicked(element)">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Phone">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.table.phone" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" (click)="entryClicked(element)">
          {{ element.number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="löschen">
        <th mat-header-cell *matHeaderCellDef>
          {{ "ui.button.phone.book.delete" | translate }}
        </th>

        <td mat-cell *matCellDef="let element">
          <button
            (click)="removeData(element)"
            class="remove-button-phone"
            mat-icon-button 
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </section>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    #paginator
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
