import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  DataProperty,
  MatFilterTableComponent,
  MatFilterTableDataSource,
  TableColumn,
  TableData,
} from 'mat-filter-table';
import { MapsService } from 'src/app/MapsService';
import { AzureMapComponent } from 'src/app/azure-map/azure-map.component';
import {
  ContainerHistoryDto,
  ContainerHistoryItemDto,
} from 'src/app/models/containerHistoryDto';
import { ContainerWithCurrentPositionDto } from 'src/app/models/containerWithCurrentPositionDto';
import { ContainersService } from 'src/app/services/containers.service';

@Component({
  selector: 'app-mandants',
  templateUrl: './containers.component.html',
  styleUrls: ['./containers.component.css'],
})
export class ContainersComponent implements OnInit, AfterViewInit {
  dataSource: MatFilterTableDataSource = new MatFilterTableDataSource();
  dataProperty: DataProperty[] = new Array<DataProperty>();
  @ViewChild('table') table: MatFilterTableComponent;
  dataSourceDetails: DataProperty[] = new Array<DataProperty>();

  mapReady = false;
  @ViewChild(AzureMapComponent) map: MapsService;

  displayedColumns: string[] = [
    'containerNr',
    'currentAddressName',
    'deliveryNoteNumber',
    'status',
    'statusTime',
  ];
  dataSourceTable = new MatTableDataSource<ContainerWithCurrentPositionDto>([]);
  displayedColumnDetails: string[] = [
    'deliveryNoteNumber',
    'status',
    'statusTime',
    'currentAddressNumber',
    'currentAddressName',
  ];
  dataSourceTableDetail = new MatTableDataSource<ContainerHistoryItemDto>([]);
  selectedContainer: ContainerHistoryDto;
  showDetails: boolean = false;
  selectedDate: Date;
  @ViewChild(MatSort) sort: MatSort;
  containerDataCurrentList = new Array<ContainerWithCurrentPositionDto>();
  tableData: TableData = {} as TableData;

  constructor(
    private containersService: ContainersService,
    private httpClient: HttpClient
  ) {}

  ngAfterViewInit(): void {
    this.map.mapReady().then((res) => {
      this.mapReady = true;
      this.initContainer();
    });
  }

  ngOnInit(): void {}

  private initContainer(): void {
    this.containersService.getAllContainerDataCurrent().subscribe(
      (containerDataCurrentList) => {
        const dataFields = [
          {
            label: 'Containernummer',
            name: 'containerNumber',
            thCss: { 'background-color': 'white' },
          },
          {
            label: 'Kunde / Anlage',
            name: 'address',
            thCss: { 'background-color': 'white' },
          },
          {
            label: 'Liefernummer',
            name: 'deliveryNoteNumber',
            thCss: { 'background-color': 'white' },
          },
          {
            label: 'Status',
            name: 'status',
            thCss: { 'background-color': 'white' },
          },
          {
            label: 'Status Zeit',
            name: 'statusTime',
            thCss: { 'background-color': 'white' },
          },
        ] as TableColumn[];
        this.tableData.columns = dataFields;
        this.tableData.rows = [];

        containerDataCurrentList.forEach((x) => {
          this.tableData.rows.push({
            id: x.containerId.toString(),
            containerNumber: x.containerNr,
            address: x.currentAddressName,
            deliveryNoteNumber: x.deliveryNoteNumber,
            status: x.status,
            statusTime: x.statusTime.toString(),
          });
        });

        this.containerDataCurrentList = containerDataCurrentList;
        this.dataSourceTable.data = containerDataCurrentList;
        this.dataSourceTable.sort = this.sort;
        this.dataSourceTable.filterPredicate = this.filterPredicate;

        this.dataSource.setData(this.tableData);
        this.map.showContainers(containerDataCurrentList);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  private transformDetailsRows(row: ContainerHistoryDto): Array<DataProperty> {
    let rowDict = Array<DataProperty>();
    var index = 0;
    for (let container of row.containerDatas) {
      let rowObject: DataProperty = {};

      rowObject['id'] = index.toString();
      rowObject['deliveryNoteNumber'] = container.deliveryNoteNumber
        ? container.deliveryNoteNumber
        : '<Containerpool>';
      rowObject['addressName'] = container.addressName;
      rowObject['addressNumber'] = container.addressNumber;
      rowObject['status'] = container.status;
      const datepipe: DatePipe = new DatePipe('en-US');
      rowObject['statusTime'] =
        datepipe.transform(container.statusTime, 'dd.MM.YYYY') +
        ' ' +
        datepipe.transform(container.statusTime, 'HH:mm') +
        ' Uhr';
      index++;
      rowDict.push(rowObject);
    }

    return rowDict;
  }

  onDoubleClick(item: DataProperty): void {
    if (this.showDetails) {
      return;
    } else {
      this.containersService
        .getContainerDataByContainerId(parseInt(item['id']))
        .subscribe((container) => {
          const dataFields = [
            {
              label: 'Auftragsnummer',
              name: 'deliveryNoteNumber',
              thCss: { 'background-color': 'white' },
            },
            {
              label: 'Status',
              name: 'status',
              thCss: { 'background-color': 'white' },
            },
            {
              label: 'Status Zeit',
              name: 'statusTime',
              thCss: { 'background-color': 'white' },
            },
            { label: 'Kundennummer / Anlagennummer', name: 'addressNumber' },
            {
              label: 'Kunde / Anlage',
              name: 'addressName',
              thCss: { 'background-color': 'white' },
            },
          ] as TableColumn[];
          this.tableData.columns = dataFields;
          this.tableData.rows = this.transformDetailsRows(container);

          this.dataSource.setData(this.tableData);

          this.showDetails = true;
          this.selectedContainer = container;
          this.dataSourceTableDetail.data = container.containerDatas;
          this.map.showContainer(container);
        });
    }
  }

  onClick(): void {
    if (this.table.dataSource.selection.selected[0] == null) {
      return;
    }

    if (this.showDetails) {
      this.map.showAndFocusContainerDetails(
        this.table.dataSource.selection.selected[0].id
      );
    } else {
      this.map.showContainersAndFocusSelected(
        this.containerDataCurrentList.find(
          (container) =>
            container.containerId ==
            this.table.dataSource.selection.selected[0].id
        )
      );
    }
  }

  dateChanged(date: Date) {
    this.selectedDate = date;
    this.containersService
      .getAllWhereGpsDataOn(date)
      .subscribe((containers) => {
        this.containerDataCurrentList = containers;
      });
  }

  filterPredicate(
    data: ContainerWithCurrentPositionDto,
    filter: string
  ): boolean {
    filter = filter.toLowerCase();
    return (
      data.containerNr.toLowerCase().includes(filter) ||
      data.containerType.toLowerCase().includes(filter) ||
      data.containerGroup.toLowerCase().includes(filter)
    );
  }

  onRowClicked(containerData: ContainerHistoryDto) {
    this.showDetails = true;
    this.containersService
      .getContainerDataByContainerId(containerData.containerId)
      .subscribe((container) => {
        this.selectedContainer = container;
        this.dataSourceTableDetail.data = container.containerDatas;
        this.map.showContainer(container);
      });
  }

  goBack() {
    this.showDetails = false;
    this.initContainer();
  }
}
