import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as atlas from 'azure-maps-control';
import { AuthenticationType, Map } from 'azure-maps-control';
import { MapsService } from '../MapsService';
import { CraftWithGpsDataHistoryDto } from '../models/CraftWithGpsDataHistoryDto';
import {
  ContainerHistoryDto,
  ContainerHistoryItemDto,
} from '../models/containerHistoryDto';
import { ContainerWithCurrentPositionDto } from '../models/containerWithCurrentPositionDto';
import { CraftWithGpsDataDto } from '../models/craft';

@Component({
  selector: 'app-azure-map',
  templateUrl: './azure-map.component.html',
  styleUrls: ['./azure-map.component.css'],
})
export class AzureMapComponent implements OnInit, MapsService {
  @ViewChild('map', { static: true })
  public mapContainer: ElementRef;
  map: Map;

  lineSource = new atlas.source.DataSource();
  pointSource = new atlas.source.DataSource();
  symbolLayer: atlas.layer.SymbolLayer;

  constructor(public datepipe: DatePipe) {}

  points = [];

  ngOnInit(): void {}
  mapReady(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.map = new Map(this.mapContainer.nativeElement, {
        center: [11.016, 49.13],
        zoom: 10,
        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: 'i365tQCM3qS2kkcTtRXl7x5StoWiBgf67N3V2nmylRM',
        },
      });

      this.map.events.add('ready', () => {
        this.map.controls.add(new atlas.control.ZoomControl());
        this.map.sources.add(this.lineSource);
        this.map.sources.add(this.pointSource);
        resolve(true);
      });
    });
  }

  clearDataSources(): void {
    this.lineSource.clear();
    this.pointSource.clear();
    this.popup?.close();
  }

  setCrafts(craft: CraftWithGpsDataDto[]): void {
    this.clearDataSources();
    var popupTemplate =
      '<div class="customInfobox"><div class="name">{name}</div>{description}' +
      '</br>{lastGpsTime}' +
      '</div>';
    var popup = new atlas.Popup({
      pixelOffset: [0, -18],
      closeButton: false,
    });

    const crafstWithGpsData = craft.filter((x) => x.gpsData != null);

    crafstWithGpsData.forEach((craft) => {
      var point = new atlas.data.Feature(
        new atlas.data.Point([craft.gpsData.geoX, craft.gpsData.geoY]),
        {
          title: craft.craftSign,
          name: craft.craftSign,
          description: craft.category,
          lastGpsTime: craft.gpsData.geoTime,
        }
      );
      this.pointSource.add([point]);
    });

    var symbolLayer = new atlas.layer.SymbolLayer(this.pointSource, null, {
      iconOptions: {
        allowOverlap: true,
      },
      textOptions: {
        allowOverlap: true,
        textField: ['get', 'title'],
        size: 15,
        anchor: 'center',
        offset: [0, 0.8],
      },
    });

    this.map.events.add('mouseover', symbolLayer, (e) => {
      if (e.shapes && e.shapes.length > 0) {
        var content, coordinate;
        var shape = e.shapes[0];
        var properties =
          shape instanceof atlas.Shape ? shape.getProperties() : '';
        content = popupTemplate
          .replace(/{name}/g, properties.name)
          .replace(/{description}/g, properties.description)
          .replace(/{lastGpsTime}/g, properties.lastGpsTime);
        coordinate = shape instanceof atlas.Shape ? shape.getCoordinates() : '';

        popup.setOptions({
          content: content,
          position: coordinate,
        });
        popup.open(this.map);
      }
    });
    this.map.events.add('mouseleave', symbolLayer, function () {
      popup.close();
    });

    this.map.layers.add(symbolLayer);

    this.map.setCamera({
      center: [
        crafstWithGpsData[crafstWithGpsData.length - 1].gpsData.geoX,
        crafstWithGpsData[crafstWithGpsData.length - 1].gpsData.geoY,
      ],
    });
  }

  showCraft(craft: CraftWithGpsDataDto[]): void {
    this.clearDataSources();

    craft.forEach((craft) => {
      var point = new atlas.data.Feature(
        new atlas.data.Point([craft.gpsData.geoX, craft.gpsData.geoY]),
        {
          title: craft.craftSign,
          name: craft.craftSign,
          description: craft.category,
        }
      );
      this.pointSource.add([point]);
    });

    this.map.layers.add(
      new atlas.layer.SymbolLayer(this.pointSource, null, {
        iconOptions: {
          allowOverlap: true,
        },
        textOptions: {
          allowOverlap: true,
          textField: ['get', 'title'],
          size: 15,
          anchor: 'center',
          offset: [0, 0.8],
        },
      })
    );
    this.map.setCamera({
      center: [
        craft[craft.length - 1].gpsData.geoX,
        craft[craft.length - 1].gpsData.geoY,
      ],
    });
  }

  setCamera(geoX: number, geoY: number): void {
    this.map.setCamera({
      center: [geoX, geoY],
    });
  }

  showContainer(container: ContainerHistoryDto) {
    this.clearDataSources();
    this.points = [];
    this.map.imageSprite
      .add('container', 'assets/markers/container.png')
      .then(() => {
        container.containerDatas.forEach((position) => {
          if (position.gpsData.geoX == null || position.gpsData.geoY == null) {
            return;
          }

          var geoX = parseFloat(position.gpsData.geoX.toFixed(4));
          var geoY = parseFloat(position.gpsData.geoY.toFixed(4));

          while (this.checkForDuplicate(this.points, geoX, geoY)) {
            geoX += 0.00003;
            geoY += 0.00003;
          }

          this.points.push({ position: position, geoX: geoX, geoY: geoY });

          var point = new atlas.data.Feature(
            new atlas.data.Point([geoX, geoY]),
            {
              position,
              statusTime: this.datepipe.transform(
                position.gpsData.geoTime,
                'dd.MM.yyyy HH:mm'
              ),
            }
          );

          this.pointSource.add(point);
        });

        this.symbolLayer = new atlas.layer.SymbolLayer(this.pointSource, null, {
          iconOptions: {
            allowOverlap: true,
            image: 'container',
          },
        });
        this.setMouseEvent(this.symbolLayer);
        this.map.layers.add(this.symbolLayer);
      });

    this.setCamera(
      container.containerDatas[container.containerDatas.length - 1].gpsData
        .geoX,
      container.containerDatas[container.containerDatas.length - 1].gpsData.geoY
    );
  }

  showAndFocusContainerDetails(id: number): void {
    this.showPopupDetails(id);
  }

  showContainersAndFocusSelected(
    container: ContainerWithCurrentPositionDto
  ): void {
    this.showPopup(container);
    this.setCamera(container.gpsData.geoX, container.gpsData.geoY);
  }

  showRoute(craftData: CraftWithGpsDataHistoryDto): void {
    this.clearDataSources();

    var point = new atlas.data.Feature(
      new atlas.data.Point([
        craftData.gpsDatas[craftData.gpsDatas.length - 1].geoX,
        craftData.gpsDatas[craftData.gpsDatas.length - 1].geoY,
      ]),
      {
        title: craftData.craftSign,
        name: craftData.craftSign,
        description: craftData.category,
      }
    );
    this.pointSource.add([point]);

    var position: atlas.data.Position[] = [];

    craftData.gpsDatas.forEach((gpsPos) => {
      position.push([gpsPos.geoX, gpsPos.geoY]);
    });

    var lineString: atlas.data.LineString = new atlas.data.LineString(position);

    this.map.imageSprite
      .add(
        'arrow-icon',
        'https://s3-us-west-2.amazonaws.com/s.cdpn.io/1717245/purpleArrowRight.png'
      )
      .then(() => {
        this.lineSource.add(new atlas.data.Feature(lineString));

        this.map.layers.add([
          //Add a line layer for displaying the line.
          new atlas.layer.LineLayer(this.lineSource, null, {
            strokeColor: 'green',
            strokeWidth: 3,
          }),
          //Add a symbol layer for rendering the arrow along the line.
          new atlas.layer.SymbolLayer(this.lineSource, null, {
            //Specify how much space should be between the symbols in pixels.
            lineSpacing: 100,

            //Tell the symbol layer that the symbols are being rendered along a line.
            placement: 'line',
            iconOptions: {
              image: 'arrow-icon',
              allowOverlap: true,
              anchor: 'center',
              size: 0.8,
            },
          }),
          new atlas.layer.SymbolLayer(this.pointSource, null, {
            iconOptions: {
              allowOverlap: true,
            },
            textOptions: {
              allowOverlap: true,
              textField: ['get', 'title'],
              size: 15,
              anchor: 'center',
              offset: [0, 0.8],
            },
          }),
        ]);
        this.map.setCamera({
          center: [
            craftData.gpsDatas[craftData.gpsDatas.length - 1].geoX,
            craftData.gpsDatas[craftData.gpsDatas.length - 1].geoY,
          ],
        });
      });
  }

  popup: atlas.Popup = new atlas.Popup({
    pixelOffset: [0, -18],
    closeButton: false,
  });

  showContainers(containerDataCurrentList: ContainerWithCurrentPositionDto[]) {
    this.clearDataSources();
    var points = [];
    this.map.imageSprite
      .add('container', 'assets/markers/container.png')
      .then(() => {
        containerDataCurrentList.forEach((container) => {
          if (
            container.gpsData.geoX == null ||
            container.gpsData.geoY == null
          ) {
            return;
          }

          var geoX = parseFloat(container.gpsData.geoX.toFixed(4));
          var geoY = parseFloat(container.gpsData.geoY.toFixed(4));

          while (this.checkForDuplicate(points, geoX, geoY)) {
            geoX += 0.00003;
            geoY += 0.00003;
          }

          this.points.push({
            id: container.containerId,
            geoX: geoX,
            geoY: geoY,
          });

          points.push({ geoX, geoY });

          var point = new atlas.data.Feature(
            new atlas.data.Point([geoX, geoY]),
            {
              container,
              statusTime: this.datepipe.transform(
                container.statusTime,
                'dd.MM.yyyy HH:mm'
              ),
            }
          );

          this.pointSource.add(point);
        });

        this.symbolLayer = new atlas.layer.SymbolLayer(this.pointSource, null, {
          iconOptions: {
            allowOverlap: true,
            image: 'container',
          },
        });
        this.setMouseEvent(this.symbolLayer);
        this.map.layers.add(this.symbolLayer);
        this.map.setCamera({
          center: [
            containerDataCurrentList[containerDataCurrentList.length - 1]
              .gpsData.geoX,
            containerDataCurrentList[containerDataCurrentList.length - 1]
              .gpsData.geoY,
          ],
        });
      });
  }

  checkForDuplicate(points: any[], x: number, y: number): boolean {
    return points.some((point) => point.geoX === x && point.geoY === y);
  }

  showPopup(container: ContainerWithCurrentPositionDto): void {
    var point = this.points.find((point) => point.id == container.containerId);
    this.popup.setOptions({
      content: this.getConent(container),
      position: new atlas.data.Position(point.geoX, point.geoY),
    });
    this.popup.open(this.map);
  }

  showPopupDetails(id: number): void {
    var point = this.points[id];
    this.setCamera(point.geoX, point.geoY);
    this.popup.setOptions({
      content: this.getConentDetails(point.position),
      position: new atlas.data.Position(point.geoX, point.geoY),
    });
    this.popup.open(this.map);
  }

  getConent(container: ContainerWithCurrentPositionDto): string {
    var template =
      '<div class="customInfobox"><div class="container">{containerNr}</div>{containerType}</br>{containerGroup}</br>{address}' +
      '</br>{deliveryNote}</br>{status}</br>{statusTime}</div>';
    var content = template
      .replace(/{containerNr}/g, 'Container Nr.: ' + container.containerNr)
      .replace(/{containerType}/g, 'Container Typ: ' + container.containerType)
      .replace(
        /{containerGroup}/g,
        'Container Gruppe: ' + container.containerGroup
      )
      .replace(/{address}/g, 'Lieferort:  ' + container.currentAddressName)
      .replace(
        /{deliveryNote}/g,
        'Lieferschein: ' + container.deliveryNoteNumber
      )
      .replace(/{status}/g, 'Status: ' + container.status)
      .replace(
        /{statusTime}/g,
        'Statuszeit: ' +
          this.datepipe.transform(container.statusTime, 'dd.MM.yyyy HH:mm') +
          ' Uhr'
      );

    return content;
  }

  getConentDetails(container: ContainerHistoryItemDto): string {
    var template =
      '<div class="customInfobox">{address}' +
      '</br>{deliveryNote}</br>{status}</br>{statusTime}</div>';
    var content = template
      .replace(/{address}/g, 'Lieferort:  ' + container.addressName)
      .replace(
        /{deliveryNote}/g,
        'Lieferschein: ' + container.deliveryNoteNumber
      )
      .replace(/{status}/g, 'Status: ' + container.status)
      .replace(
        /{statusTime}/g,
        'Statuszeit: ' +
          this.datepipe.transform(container.statusTime, 'dd.MM.yyyy HH:mm') +
          ' Uhr'
      );

    return content;
  }

  clickEvent(e: atlas.MapMouseEvent): void {
    if (e.shapes && e.shapes.length > 0) {
      var content, coordinate;
      var shape = e.shapes[0];
      var properties =
        shape instanceof atlas.Shape ? shape.getProperties() : '';

      if (properties.container) {
        var container: ContainerWithCurrentPositionDto = JSON.parse(
          JSON.stringify(properties.container)
        );
        content = this.getConent(container);
      } else if (properties.position) {
        var position: ContainerHistoryItemDto = JSON.parse(
          JSON.stringify(properties.position)
        );
        content = this.getConentDetails(position);
      }

      coordinate = shape instanceof atlas.Shape ? shape.getCoordinates() : '';
      this.popup.setOptions({
        content: content,
        position: coordinate,
      });
      this.popup.open(this.map);
    }
  }

  setMouseEvent(layer: atlas.layer.SymbolLayer): void {
    var callback = (e: atlas.MapMouseEvent) => {
      this.clickEvent(e);
    };
    this.map.events.add('click', layer, callback);
    this.map.events.add('click', () => {
      this.popup.close();
    });
  }
}
