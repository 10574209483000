import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CraftWithGpsDataHistoryDto } from '../models/CraftWithGpsDataHistoryDto';
import { CraftWithGpsDataDto, HasRouteForCraft } from '../models/craft';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CraftsService {
  constructor(private apiService: ApiService, public datePipe: DatePipe) {}

  getAll(): Observable<CraftWithGpsDataDto[]> {
    return this.apiService.get('CraftTracking');
  }

  getAllWhereGpsDataOn(date: Date) {
    var dateFormated = this.datePipe.transform(date, 'yyyy-MM-dd');
    return this.apiService.get('Crafts/GpsDataOnDate/' + dateFormated);
  }

  getByIdAndDate(
    craftId: number,
    date: Date
  ): Observable<CraftWithGpsDataHistoryDto> {
    var dateFormated = this.datePipe.transform(date, 'yyyy-MM-dd');
    return this.apiService.get(
      'CraftTracking/' + craftId + '/GpsDataOnDate/' + dateFormated
    );
  }

  get(id: number): Observable<CraftWithGpsDataDto> {
    return this.apiService.get('CraftTracking/' + id);
  }

  getAllGpsDataCurrent(): Observable<CraftWithGpsDataDto[]> {
    return this.apiService.get('CraftTracking');
  }

  checkHasRoute(date: Date): Observable<HasRouteForCraft[]> {
    var dateFormated = this.datePipe.transform(date, 'yyyy-MM-dd');
    return this.apiService.get('CraftTracking/CheckHasRoute/' + dateFormated);
  }
}
