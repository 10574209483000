import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MessagingServiceService } from 'src/app/services/messaging-service.service';



@Component({
  selector: 'app-table-input',
  templateUrl: './table-input.component.html',
  styleUrls: ['./table-input.component.css'],
})
export class TableInputComponent implements OnInit {
  @ViewChild('paginator', { static: true }) paginator!: MatPaginator;
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatSort)
  sort!: MatSort;

  displayedColumns: string[] = [];
  dataSource: any;
  constructor(
    private messagingService: MessagingServiceService,
    private router: Router
  ) {}


  ngOnInit(): void {
    this.displayedColumns = ['senderName', 'messageText', 'sendDateTime'];
    this.messagingService.getIncommingMessages().subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.sort.sort(<MatSortable>{
        id: 'sendDateTime',
        start: 'desc',
      });
      this.dataSource.sort = this.sort;
    });
  }

  messageClicked(row: PeriodicElement) {
    if (!row.isRead) {
      this.messagingService.markMessageAsRead(row.id).subscribe((data) => {});
    }
    this.router.navigate(['/show-messages', row.messageText]);
  }
}

export interface PeriodicElement {
  messageText: string;
  senderName: string;
  isRead: boolean;
  id: number;
  sendDateTime: Date;
}
