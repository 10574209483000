<div class="neuer-phone-eintrag">
  <h1 class="npe">{{ "ui.table.new.phone.entry" | translate }}</h1>
</div>

<form class="enter-messages">
  <mat-form-field class="messages-width">
    <mat-label>{{ "ui.table.enter.name" | translate }}</mat-label>
    <input
      matInput
      #message
      type="text"
      id="nameText"
      [formControl]="nameText"
    />
  </mat-form-field>
</form>

<form class="enter-messages">
  <mat-form-field class="messages-width">
    <mat-label>{{ "ui.table.enter.phone" | translate }}</mat-label>
    <input
      matInput
      #message
      type="text"
      id="phoneText"
      [formControl]="phoneText"
    />
  </mat-form-field>
</form>
<div class="example-button-row">
  <button mat-raised-button (click)="sendMessage()" color="primary">
    {{ "ui.support.safe" | translate }}
  </button>
  <button mat-raised-button routerLink="/phone-book" color="accent">
    {{ "ui.table.cancel" | translate }}
  </button>
</div>
