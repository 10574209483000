<div class="select">
  <mat-form-field appearance="fill">
    <mat-label>{{ "ui.support.command" | translate }}</mat-label>
    <mat-select disableRipple>
      <!--

  <mat-option *ngFor="let commands of commandlist" [value]="commands">{{commands}}</mat-option>

-->
      <mat-option value="1">Befehl 1</mat-option>
      <mat-option value="2">Befehl 2</mat-option>
      <mat-option value="3">Befehl 3</mat-option>
    </mat-select>
  </mat-form-field>
</div>
