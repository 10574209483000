import { CommonModule, DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatFilterTableModule } from 'mat-filter-table';
import { ChartsModule } from 'ng2-charts';
import { AppComponent } from './app.component';
import { AuthCallBackComponent } from './authorization/auth-call-back/auth-call-back.component';
import { AuthInterceptor } from './authorization/intercepter/auth.interceptor';
import { ForbiddenInterceptor } from './authorization/intercepter/forbidden.interceptor';
import { OrganizationIdInterceptor } from './authorization/intercepter/organizationId.interceptor';
import { LoginComponent } from './authorization/login/login.component';
import { AuthGuardService } from './authorization/services/auth-guard.service';
import { AuthService } from './authorization/services/auth.service';
import { AzureMapComponent } from './azure-map/azure-map.component';
import { DeleteEntryDialogComponent } from './common/delete-entry-dialog/delete-entry-dialog.component';
import { NeedsRoleDirective } from './common/needs-role/needs-role.directive';
import { MaterialModule } from './material-module';
import { ContainersComponent } from './modules/containers/containers.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { MessageComponent } from './modules/messages/message/message.component';
import { NewMessageComponent } from './modules/messages/new-message/new-message.component';
import { ShowMessageComponent } from './modules/messages/show-message/show-message.component';
import { TableInputComponent } from './modules/messages/table-input/table-input.component';
import { TableOutputComponent } from './modules/messages/table-output/table-output.component';
import { NewPhoneBookEntryComponent } from './modules/phonebook/new-phone-book-entry/new-phone-book-entry.component';
import { PhoneBookComponent } from './modules/phonebook/phone-book/phone-book.component';
import { PhoneEditComponent } from './modules/phonebook/phone-edit/phone-edit.component';
import { SelectComponent } from './modules/support/select/select.component';
import { SupportComponent } from './modules/support/support.component';
import { NewUserComponent } from './modules/user/new-user/new-user.component';
import { UserAdministrationEditComponent } from './modules/user/user-administration-edit/user-administration-edit.component';
import { UserAdministrationComponent } from './modules/user/user-administration/user-administration.component';
import { HeaderComponent } from './navigation/header/header.component';
import {MatDialogModule} from '@angular/material/dialog';

import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { Role } from './authorization/services/role.service';
import { LagerverwaltungComponent } from './modules/lagerverwaltung/lagerverwaltung.component';
import { RemoveDialogComponent } from './remove-dialog/remove-dialog.component';

export const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'auth-callback',
    component: AuthCallBackComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'containers',
    component: ContainersComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.CONTAINER_TRACKING] },
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.SystemAdmin] },
  },
  {
    path: 'messages',
    component: MessageComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.MESSAGE_CENTER] },
  },
  {
    path: 'new-messages',
    component: NewMessageComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.MESSAGE_CENTER] },
  },
  {
    path: 'show-messages/:messageText',
    component: ShowMessageComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.MESSAGE_CENTER] },
  },
  {
    path: 'phone-book',
    component: PhoneBookComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.PHONEBOOK_MANAGEMENT] },
  },
  {
    path: 'new-phone-book-entry',
    component: NewPhoneBookEntryComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.PHONEBOOK_MANAGEMENT] },
  },
  {
    path: 'phone-edit/:id/:name/:number',
    component: PhoneEditComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.PHONEBOOK_MANAGEMENT] },
  },
  {
    path: 'user-administration',
    component: UserAdministrationComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.DRIVER_MANAGEMENT] },
  },
  {
    path: 'new-user',
    component: NewUserComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.DRIVER_MANAGEMENT] },
  },
  {
    path: 'user-administration-edit/:id/:firstName/:lastName/:pin/:employeeNr',
    component: UserAdministrationEditComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.DRIVER_MANAGEMENT] },
  },
  {
    path: 'lagerverwaltung',
    component: LagerverwaltungComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.PLANT_MANAGEMENT] },
  },
];

export function translateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ContainersComponent,
    HeaderComponent,
    DeleteEntryDialogComponent,
    NeedsRoleDirective,
    AuthCallBackComponent,
    LoginComponent,
    DashboardComponent,
    AzureMapComponent,
    SupportComponent,
    NewMessageComponent,
    ShowMessageComponent,
    TableOutputComponent,
    TableInputComponent,
    MessageComponent,
    SelectComponent,
    PhoneBookComponent,
    UserAdministrationComponent,
    NewPhoneBookEntryComponent,
    NewUserComponent,
    UserAdministrationEditComponent,
    PhoneEditComponent,
    LagerverwaltungComponent,
    RemoveDialogComponent,
  ],

  imports: [
    MatFilterTableModule,
    TranslateModule.forChild({ extend: true }),
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(ROUTES),
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    ChartsModule,
    FormsModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTableModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatIconModule,
    MatSortModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatDialogModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en-US',
    }),
  ],
  providers: [
    AuthService,
    AuthGuardService,
    DatePipe,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrganizationIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForbiddenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
