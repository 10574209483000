import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Container } from '../models/container';
import { ContainerHistoryDto } from '../models/containerHistoryDto';
import { ContainerWithCurrentPositionDto } from '../models/containerWithCurrentPositionDto';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ContainersService {
  constructor(private apiService: ApiService, public datePipe: DatePipe) {}

  getAll(): Observable<Container[]> {
    return this.apiService.get('ContainerTracking');
  }

  getAllWhereGpsDataOn(
    date: Date
  ): Observable<ContainerWithCurrentPositionDto[]> {
    var dateFormated = this.datePipe.transform(date, 'yyyy-MM-dd');
    return this.apiService.get(
      'ContainerTracking/ContainerDataOnDate/' + dateFormated
    );
  }

  getContainerDataByContainerId(
    containerId: number
  ): Observable<ContainerHistoryDto> {
    return this.apiService.get(
      'ContainerTracking/ContainerData/' + containerId
    );
  }

  getByIdAndDate(id: number, date: Date): Observable<Container> {
    var dateFormated = this.datePipe.transform(date, 'yyyy-MM-dd');
    return this.apiService.get(
      'ContainerTracking/' + id + '/ContainerDataOnDate/' + dateFormated
    );
  }

  get(id: number): Observable<ContainerWithCurrentPositionDto> {
    return this.apiService.get('ContainerTracking/' + id);
  }

  getAllContainerDataCurrent(): Observable<ContainerWithCurrentPositionDto[]> {
    return this.apiService.get('ContainerTracking/ContainerDataCurrent');
  }
}
