<div class="page">
  <div class="card">
    <div class="header">
      <h3>{{ "ui.label.login.loggingin" | translate }}</h3>
    </div>
    <div class="body">
      <div
        style="
          display: flex;
          flex-flow: column;
          align-items: center;
          margin: 3%;
        "
      >
        <mat-spinner
          color="primary"
          [mode]="'indeterminate'"
          [diameter]="50"
          style="margin: 10%"
        ></mat-spinner>
      </div>
    </div>
  </div>
</div>
