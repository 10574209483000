<div class="phone-edit">
  <h1 class="pe">{{ "ui.table.edit.phone.book" | translate }}</h1>
</div>

<form class="example-form">
  <mat-form-field class="example-width">
    <mat-label>{{ "ui.table.enter.name" | translate }}</mat-label>
    <input matInput #message [formControl]="nameText" />
  </mat-form-field>
</form>

<form class="example-form">
  <mat-form-field class="example-width">
    <mat-label>{{ "ui.table.enter.phone" | translate }}</mat-label>
    <input matInput #message [formControl]="phoneText" />
  </mat-form-field>
</form>

<div class="example-button-row">
  <button mat-raised-button (click)="save()" color="primary">
    {{ "ui.support.safe" | translate }}
  </button>
  <button mat-raised-button routerLink="/phone-book" color="accent">
    {{ "ui.table.cancel" | translate }}
  </button>
</div>
