<mat-drawer-container class="container">
  <mat-drawer mode="side" opened class="side-panel">
    <mat-form-field appearance="fill" class="date-input">
      <mat-label>Date filter</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [formControl]="defaultDate"
        (dateInput)="dateChanged($event.value)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div class="craft-list">
      <div class="no-vehicle-found" *ngIf="noDataFound">
        No vehicle data on selected Date.
      </div>
      <mat-tree
        [dataSource]="dataSource"
        [treeControl]="treeControl"
        class="craft-tree"
      >
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <mat-checkbox
            color="primary"
            (change)="onSelectedCraftChange(node.craft, $event)"
          >
            {{ node.craft.craftSign }}
          </mat-checkbox>
          <button
            mat-icon-button
            (click)="showRoute(node.craft)"
            [disabled]="!node.craft.hasRoute"
            [matTooltip]="'ui.tooltip.showRoute' | translate"
          >
            <mat-icon>route</mat-icon>
          </button>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <div class="mat-tree-node">
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name"
            >
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
                }}
              </mat-icon>
            </button>
            {{ node.name }}
          </div>
          <div
            [class.craft-tree-invisible]="!treeControl.isExpanded(node)"
            role="group"
          >
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <app-azure-map #map></app-azure-map>
  </mat-drawer-content>
</mat-drawer-container>
